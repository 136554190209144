import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Navbar, NavbarToggler, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import darkLogo from "../images/black-logo.svg"
import lightLogo from "../images/white-logo.svg"


function Header ({ aboutTitle, staffTitle, officesTitle, siteTitle, path, darkBackground, lang,  isHome = false, pageLanguage, uid })  {
  
  const prefix = lang === 'et-et' ? `` : `${lang}/`;
  let removePref = pageLanguage === 'et-et'  ?  path
:  path.split('/').slice(2).join('/')
//removePref = removePref.replace('/', '' )
  return( 
    <Navbar 
      color={darkBackground ? "dark" : "light"}
      dark={darkBackground}
      light={!darkBackground}
      className={`sticky-top ${isHome ? 'navbar-home' : null}`}
    >
      <Container fluid="sm" className="py-2">
        <a className="navbar-brand" href="/">
          <img 
            className="d-lg-none"
            src={darkBackground ? lightLogo : darkLogo}
            alt={siteTitle}
            style={{
              width: `30px`,
              height: `30px`,
              marginBottom: `0`
            }}
          />
          <img 
            className="d-none d-lg-block"
            src={lightLogo}
            alt={siteTitle}
            style={{
              width: `30px`,
              height: `30px`,
              marginBottom: `0`
            }}
          />
        </a>
        <div className="d-flex align-items-center">
          <div className="languages px-1">
            <a className={`px-1 ${pageLanguage === 'ee' ? 'font-weight-bold' : ''}`} href={`/${uid}`}>EST</a>
            <a className={`px-1 ${pageLanguage === 'en' ? 'font-weight-bold' : ''}`} href={`/en/${uid}`}>ENG</a>
            <a className={`px-1 ${pageLanguage === 'ru' ? 'font-weight-bold' : ''}`} href={`/ru/${uid}`}>RUS</a>
          </div>
          <UncontrolledDropdown className="d-lg-none" nav inNavbar>
            <DropdownToggle nav>
              <NavbarToggler />
            </DropdownToggle>
            <DropdownMenu right>
             <DropdownItem>
                <Link to={`${prefix}`}>
                  {aboutTitle ? aboutTitle : `About`}
                </Link>
              </DropdownItem>
              <DropdownItem>
              <Link to={`/legalia`}>
                  {siteTitle ? siteTitle : `Legalia`}
                  </Link>
              </DropdownItem>
              <DropdownItem>
                <a href={`/team`}>
                  {staffTitle ? staffTitle : `Team`}
                </a>
              </DropdownItem>
              <DropdownItem>
                <a href={`/${prefix}contact`}>
                  {officesTitle ? officesTitle : `Contact`}
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown className="d-none d-lg-block" nav inNavbar>
            <DropdownToggle nav>
              <NavbarToggler />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <a href={`/${prefix}`}>
                  {siteTitle ? siteTitle : `Legalia`}
                </a>
              </DropdownItem>
              <DropdownItem>
                <a href={`/${prefix}team`}>
                  {staffTitle ? staffTitle : `Team`}
                </a>
              </DropdownItem>
              <DropdownItem>
                <a href={`/${prefix}contact`}>
                  {officesTitle ? officesTitle : `Contact`}
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
