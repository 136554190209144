import React from "react"
import PropTypes from "prop-types"
import "./layout.scss"
import Header from "../components/header"
const Layout = ({ aboutTitle, staffTitle, officesTitle, children, path, darkBackground, lang, isHome, pageLanguage, uid }) => {

  return (
    <>
      <Header 
        aboutTitle={aboutTitle} 
        staffTitle={staffTitle} 
        officesTitle={officesTitle} 
        path={path} 
        darkBackground={darkBackground}
        lang={lang}
        isHome={isHome}
        pageLanguage={pageLanguage}      
        uid={uid}></Header>
      <main>
        {children}
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
